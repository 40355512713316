import { AUTH_ACTIONS } from '../../../store/actions'
import authService from '../../../services/auth'
import notisphereServices from '../../../services/notisphere/email'
import selectUserTypesComponent from './select-user-type'
import 'cross-fetch/polyfill'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import conf from '../../../config'
export let Session = ''
export let IsRememberDevice = `${conf.REMEMBER_DEVICE}`
export let IsMfaOTP = false
export let OtpSession = ''
export let IsVerification = false
export let IsMfa = false
export let IsCodeSent = false
export let IsVerified = false
export let IsResendOTP = false
export let cognitoUser = null
export let instance = this
export let poolData = { UserPoolId: `${conf.USER_POOL_ID}`, ClientId: `${conf.POOL_CLIENT_ID}`}
export let userPool = null
export const cognitoCallbacks = {
    mfaSetup: function(challengeName, challengeParameters) {
        cognitoUser.associateSoftwareToken(this)
    },
    selectMFAType: function(challengeName, challengeParameters) {
        var mfaType = prompt('Please select the MFA method.', '') // valid values for mfaType is "SMS_MFA", "SOFTWARE_TOKEN_MFA"
        cognitoUser.sendMFASelectionAnswer(mfaType, this)
    },
    mfaRequired(result, ChallengeP) {
        instance._loadingStart()
        instance.Restore()
        IsCodeSent = true
        instance.otpNumber = instance.SqlNumber.substr(instance.SqlNumber.length - 4)
        instance.isPassword = false
        instance.isCode = true
        instance.codereq = 'Y'
        instance.isNum = false
        instance.numreq = ''
        instance.isChangeNumber = true
        instance.isAddedNumber = false
        Session =  cognitoUser.Session
        IsMfaOTP = true
        IsMfa = true
        instance._loadingEnd()
        if(IsResendOTP)
            instance._showToast('OTP sent successfully', { variant: 'success' })
        IsResendOTP = false
    },
    onSuccess (result) {
        IsCodeSent = false
        instance.code = ''
        if(IsRememberDevice == 1)
            instance.SetDeviceStorage()
        if(instance.NumberVerified == 'false' || instance.NumberVerified == '')
        {
            cognitoUser.getAttributeVerificationCode('phone_number', {
                onSuccess: function(result) {
                },
                onFailure: function(err) {
                    instance._loadingEnd()
                    instance._showToast('Phone number verification attempt exceeded', { variant: 'warning' })
                },
                inputVerificationCode: function() {
                    instance.otpNumber = instance.SqlNumber.substr(instance.SqlNumber.length - 4)
                    instance.isPassword = false
                    instance.isCode = true
                    instance.codereq = 'Y'
                    instance.isNum = false
                    instance.numreq = ''
                    instance.isChangeNumber = true
                    instance.isAddedNumber = false
                    Session =  cognitoUser.Session
                    IsMfaOTP = false
                    IsMfa = true
                    IsCodeSent = true
                    instance._loadingEnd()
                },
            })
        }
        else
        {
            cognitoUser.setDeviceStatusRemembered({
                onSuccess: function(result) {
                },
                onFailure: function(err) {
                    alert(err.message || JSON.stringify(err))
                },
            })
            instance.signIn()
        }
    },
    onFailure (err) {
        instance._loadingEnd()
        if(err.message == 'Incorrect username or password.')
            instance.UserLockout()
        else if(err.message == 'Invalid session for the user, session can only be used once.')
        {
            instance.UserLockoutOTP()
        }
        else
            instance.UserLockoutOTP()
        instance._loadingEnd()
    }
}
export default {
    props: {
        clickOutside: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            email: '',
            password: '',
            code: '',
            isCode: false,
            isChangeNumber: false,
            isAddedNumber: false,
            isPassword: true,
            VerificationSent: '',
            IsAdmin: false,
            IsSSO: false,
            IsLockout: false,
            IsMFA: false,
            CognitoNumber: false,
            SqlNumber: false,
            NumberVerified: '',
            OtpSession: '',
            codereq: '',
            numValue: '',
            isNum: false,
            numreq: '',
            accessToken: '',
            otpNumber: '',
            mustSelectUserType: false,
            userData: null,
            show: null,
            contentType: 'Login',
            bodyContent: null,
            payload: {},
            isPasswordEnabled: false,
            authCode: '',
            nounce: '',
            idpName: '',
            ssoUrl: '',
            appClientId: '',
            redirectUrl: '',
            isLoginEnabled: false,
        }
    },
    components: {
        'userType': selectUserTypesComponent
    },
    created(){
        this.checkHealth()
        this.getContent()
        if(this.$route.query.code && this.$route.query.state)
        {
            let localstate = localStorage.getItem('localstate')
            if(localstate != this.$route.query.state)
            {
                instance._showToast('User not authorized', { variant: 'danger' })
                this.$router.push({name: 'Login'})
            }
            else
            {
                this.email = this.email == '' ? localStorage.getItem('localemail'): this.email
                this.authCode = this.$route.query.code
                this.nounce = localStorage.getItem('localnounce')
                instance = this
                this.signIn('auth_code')
            }
        }
        else if (this.$route.query.error) {
            var message = this.getIdpAuthErrorMessage(this.$route.query.error,this.$route.query.error_description)
            this._showToast(message, { variant: 'danger' })
            this.$router.push({name: 'Login'})
        }
    },
    computed: {
        AuthenticationData() {
            return {
                Username: this.email,
                Password: this.password,
            }
        },
        AuthenticationDetails() {
            return new AmazonCognitoIdentity.AuthenticationDetails(
                this.AuthenticationData == undefined ? instance.AuthenticationData : this.AuthenticationData
            )
        },
        UserData() {
            return {
                Username: this.email,
                Pool: userPool,
            }
        },
        selectUserTypes() {
            return this.$store.state.selectUserTypes
        },
        buttonLabel() {
            return this.show ? 'hide' : 'show'
        },
        htmlRender() {
            return `<div> ${ this.bodyContent == null ? '' : this.bodyContent } </div>`
        },
    },
    methods: {

        getIdpAuthErrorMessage(error, errorDescription){
            switch (error) {
            case 'invalid_request':
                if (errorDescription.includes('Timeout occurred in calling IdP token endpoint')) {
                    return 'Error: Timeout occurred while requesting a token from the Identity Provider (IdP).'
                } else if (errorDescription.includes('Timeout in calling jwks uri')) {
                    return 'Error: Timeout occurred while calling the JSON Web Key Set (JWKS) endpoint for id_token validation.'
                } else if (errorDescription.includes('Error from other providers')) {
                    return 'Error: Received an error response from other federated Identity Providers:' + errorDescription
                } else if (errorDescription.includes('Connection reset')) {
                    return 'Error: Connection reset during communication with an external Identity Provider.'
                } else if (errorDescription.includes('Read timed out')) {
                    return 'Error: Read timed out during communication with an external Identity Provider.'
                } else {
                    return 'Error: Invalid request parameters -' + errorDescription
                }
            case 'unauthorized_client':
                return 'Error: The client is unauthorized to request code or token -' + errorDescription
            case 'invalid_scope':
                return 'Error: Invalid scope requested -'+ errorDescription
            case 'server_error':
                return 'Error: Unexpected server error -' + errorDescription
            default:
                return 'Error: Unknown error -' + errorDescription
            }
        },

        checkHealth(){
            authService.healthCheck(resp=>{
                return resp
            })
        },
        getContent() {
            notisphereServices.getcontent(this.contentType).then((resp) => {
                if(!resp.error){
                    this.bodyContent = resp.data.d.content
                    this.bodyContent = this.bodyContent.replaceAll('ql-align-right', 'ql-text-right').replaceAll('ql-align-center', 'ql-text-center').replaceAll('ql-align-justify', 'ql-text-justify')
                        .replaceAll('ql-indent-1','indent-1').replaceAll('ql-indent-2','indent-2').replaceAll('ql-indent-3','indent-3')
                        .replaceAll('ql-indent-4','indent-4').replaceAll('ql-indent-5','indent-5').replaceAll('ql-indent-6','indent-6')
                        .replaceAll('ql-indent-7','indent-7').replaceAll('ql-indent-8','indent-8').replaceAll('img src','img style="max-height: 185px;" src')
                }
            })
        },
        async UserLockout()
        {
            await authService.UserLockout(this.email,this.password).then(res => {
                if (res.error) {
                    this._showToast(res.error.message, { variant: 'warning' })
                }
            })
        },
        async UserLockoutOTP()
        {
            await authService.UserLockoutOTP(this.email,this.password).then(res => {
                if (res.error) {
                    this._showToast(res.error.message, { variant: 'warning' })
                }
            })
        },
        async Restore()
        {
            await authService.UserRestore(this.email,this.password).then(res => {
                if (res.error) {
                    instance._showToast(res.error.message, { variant: 'warning' })
                }
            })
        },
        async Authenticate()
        {
            if(this.code != '')
            {
                instance._loadingStart()
                IsVerified = true
                if(cognitoUser != null)
                    cognitoUser.Session = Session
                else
                {
                    userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
                    cognitoUser = new AmazonCognitoIdentity.CognitoUser(this.UserData)
                    cognitoUser.Session = Session
                }
                if(IsMfaOTP)
                    cognitoUser.sendMFACode(this.code, cognitoCallbacks)
                else
                    cognitoUser.verifyAttribute('phone_number', this.code, {
                        onSuccess: function(result) {
                            var smsMfaSettings = {
                                PreferredMfa: true,
                                Enabled: true,
                            }
                            cognitoUser.setUserMfaPreference(smsMfaSettings, null, function(err, result) {
                                if (err) {
                                    alert(err.message || JSON.stringify(err))
                                }
                            })
                            if(IsRememberDevice == 1)
                                instance.SetDeviceStorage()
                            instance.signIn()
                        },
                        onFailure: function(err) {
                            instance._loadingEnd()
                            instance._showToast('The OTP does not match. Please enter again', { variant: 'danger' })
                        },
                    })
            }
            else
            {
                IsVerified = false
                userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
                instance = this
                cognitoUser = new AmazonCognitoIdentity.CognitoUser(this.UserData)
                if(IsRememberDevice == 0)
                {
                    let arr = ['243A6021-47A0-4912-A101-C4AB08C053C8','1E890D44-A048-4A69-A951-455D739FF8D4','35FF6F68-0A95-40FB-BB54-895C484E9F38','39C7744F-41FD-4EEE-94CA-322331EB046A','app-version']
                    for (var j = 0; j<localStorage.length; j++) {
                        for (var i = 0; i<localStorage.length; i++) {

                            if(!arr.includes(localStorage.key(i)))
                            {
                                localStorage.removeItem(localStorage.key(i))

                            }
                        }
                    }
                }
                else
                {
                    userPool.storage = this.GetDeviceStorage()
                }
                if(this.VerificationSent == 'true')
                {
                    cognitoUser.initiateAuth(this.AuthenticationDetails,cognitoCallbacks)
                    this._loadingStart()
                }
                else if (this.AuthenticationDetails.password != '')
                {
                    cognitoUser.authenticateUser(this.AuthenticationDetails,cognitoCallbacks)
                    this._loadingStart()
                }
                else
                    this.isPasswordEnabled = true
            }
        },
        signIn(grantType)
        {
            //localemail
            instance.$store.dispatch(AUTH_ACTIONS.LOGIN, {
                client_id: 'web-client',
                grant_type: grantType == null ? 'password' : grantType,
                email: instance.email,
                password: instance.password,
                verification_code: instance.code,
                phone_number: instance.numValue,
                code_req: instance.codereq,
                login_as: null,
                login_as_org_id: 0,
                auth_code: instance.authCode,
                nounce: instance.nounce,
                refresh_token: instance.accessToken
            }).then(resp => {
                if(resp.data.d != undefined)
                {
                    if(resp.data.d.Challenge == 'MFA')
                    {
                        instance.isAddedNumber = false
                    }
                    else
                    {
                        instance._showToast(resp.error.message, { variant: 'warning' })
                    }
                }
                else if(resp.error)
                {
                    this.$router.push({name: 'Login'})
                }
            })
        },
        async onSubmitLogin() {
            this.IsLockout = false
            const isValid = await this.$refs['observer-login'].validate()
            if (!isValid) {
                this._showToast('Please check your information', { variant: 'warning' })
            }
            else if(IsCodeSent && this.code == '')
            {
                this._showToast('Please enter OTP', { variant: 'warning' })
            }
            else {
                await authService.GetUserDataId(this.email,this.password,this.code,'',this.OtpSession).then(res => {
                    if (!res.error && res.data.d.Error != 'true') {
                        this.IsAdmin = res.data.d.IsAdmin
                        if(res.data.d.IsSSO != undefined)
                        {
                            this.idpName = (res.data.d.IDPName == undefined || res.data.d.IDPName == null) ? '' : res.data.d.IDPName
                            this.ssoUrl = (res.data.d.SSOUrl == undefined || res.data.d.SSOUrl == null) ? '' : res.data.d.SSOUrl
                            this.appClientId = (res.data.d.AppClientId == undefined || res.data.d.AppClientId == null) ? '' : res.data.d.AppClientId
                            this.redirectUrl = (res.data.d.RedirectUrl == undefined || res.data.d.RedirectUrl == null) ? '' : res.data.d.RedirectUrl
                            this.IsSSO = res.data.d.IsSSO.toLocaleLowerCase() == 'false' ? false : true
                        }
                        if(res.data.d.IsAdmin != 'false')
                        {
                            this.IsMFA = res.data.d.IsMFA
                            this.VerificationSent = res.data.d.VerificationSent
                            this.NumberVerified = res.data.d.NumberVerified
                            this.CognitoNumber = res.data.d.CognitoNumber.replace('+1','')
                            this.SqlNumber = res.data.d.SqlNumber.replace('+1','')
                            this.OtpSession = res.data.d.OtpSession
                        }
                        instance = this
                    }
                    else if(res.data.d.ChangeNumberError != undefined && res.data.d.ChangeNumberError == 'true')
                    {
                        this.IsLockout = true
                        instance._loadingEnd()
                    }
                    else
                    {
                        this.IsLockout = true
                        this.UserLockoutOTP()
                        instance._loadingEnd()
                        this._showToast(res.error.message, { variant: 'warning' })
                    }
                })
                if(this.IsAdmin != 'false')
                {
                    if(!this.IsLockout)
                    {
                        if(this.OtpSession != '' && this.OtpSession != undefined && this.OtpSession != null)
                        {
                            instance.otpNumber = instance.SqlNumber.substr(instance.SqlNumber.length - 4)
                            instance.isPassword = false
                            instance.isCode = true
                            instance.codereq = 'Y'
                            instance.isNum = false
                            instance.numreq = ''
                            instance.isChangeNumber = true
                            instance.isAddedNumber = false
                            IsCodeSent = true
                            IsMfaOTP = false
                            IsMfa = true
                            IsVerified = true
                            instance._loadingEnd()
                        }
                        else if(OtpSession == '' || OtpSession == undefined || OtpSession == null)
                        {
                            if(this.VerificationSent == 'Success')
                            {
                                IsCodeSent = false
                                this.code = ''
                                this.signIn()
                            }
                            else if(this.VerificationSent != 'true' && this.code == '')
                            {
                                this.Authenticate()
                            }
                            else if(this.VerificationSent != 'true' && this.code != '')
                            {
                                this.Authenticate()
                            }
                            else
                            {
                                instance.otpNumber = instance.SqlNumber.substr(instance.SqlNumber.length - 4)
                                instance.isPassword = false
                                instance.isCode = true
                                instance.codereq = 'Y'
                                instance.isNum = false
                                instance.numreq = ''
                                instance.isChangeNumber = true
                                instance.isAddedNumber = false
                                IsCodeSent = true
                                IsMfaOTP = false
                                IsMfa = true
                                IsVerified = true
                                instance._loadingEnd()
                            }
                        }
                        else
                        {
                            instance.otpNumber = instance.SqlNumber.substr(instance.SqlNumber.length - 4)
                            instance.isPassword = false
                            instance.isCode = true
                            instance.codereq = 'Y'
                            instance.isNum = false
                            instance.numreq = ''
                            instance.isChangeNumber = true
                            instance.isAddedNumber = false
                            IsCodeSent = true
                            IsMfaOTP = false
                            IsMfa = true
                            IsVerified = true
                            instance._loadingEnd()
                        }
                    }
                }
                else
                {
                    if(!this.IsLockout)
                    {
                        if(!this.IsSSO && this.password == '')
                        {
                            this.isPasswordEnabled = true
                            this.isLoginEnabled = false
                        }
                        else if(!this.IsSSO && this.password != '')
                        {
                            this.signIn()
                        }
                        if(this.IsSSO)
                        {
                            let state = this.generateRandomState()
                            let nounce = this.generateRandomState()
                            localStorage.setItem('localstate',state)
                            localStorage.setItem('localnounce',nounce)
                            localStorage.setItem('localemail',this.email)
                            window.location.href = this.ssoUrl + '/oauth2/authorize?client_id=' + this.appClientId + '&response_type=code&scope=email+openid+profile&redirect_uri=' + this.redirectUrl + '&state='+ state +'&nonce=' + nounce+ '&identity_provider='+ this.idpName
                            //window.location.href = 'https://qa-notisphere.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=7h4eem7ii00dmhheqm195au4l4&response_type=code&scope=email+openid+profile&redirect_uri=http://localhost:8080/login&state='+ state +'&nonce=' + nounce+ '&identity_provider='+ this.idpName
                            //window.location.href = this.appBaseUrlSSO + '/oauth2/authorize?client_id=' + this.appClientId + '&response_type=code&scope=email+openid+profile&redirect_uri=https://qa.notispherealerts.com/login&state='+ state +'&nonce=' + nounce+ '&identity_provider='+ this.idpName
                        }
                    }
                }
            }
        },
        generateRandomState() {
            return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
        },
        InlineButtonClickHandler(event) {
            this.isPasswordEnabled = false
            this.password = ''
            this.email = ''
        },
        acceptNumber() {
            var x = this.numValue.replace('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
            this.numValue = !x[2] ? x[1] :  + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '')
        },
        async resendCode() {
            await authService.GetUserDataId(this.email,this.password,'',instance.VerificationSent == 'true' ?'Y':'').then(res => {
                if (!res.error) {
                    userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
                    instance = this
                    cognitoUser = new AmazonCognitoIdentity.CognitoUser(this.UserData)
                    if(IsRememberDevice == 0)
                    {
                        let arr = ['243A6021-47A0-4912-A101-C4AB08C053C8','1E890D44-A048-4A69-A951-455D739FF8D4','35FF6F68-0A95-40FB-BB54-895C484E9F38','39C7744F-41FD-4EEE-94CA-322331EB046A','app-version']
                        for (var j = 0; j<localStorage.length; j++) {
                            for (var i = 0; i<localStorage.length; i++) {

                                if(!arr.includes(localStorage.key(i)))
                                {
                                    localStorage.removeItem(localStorage.key(i))

                                }
                            }
                        }
                    }
                    else
                    {
                        userPool.storage = this.GetDeviceStorage()
                    }
                    IsResendOTP = true
                    IsCodeSent = true
                    if(instance.VerificationSent != 'true')
                        cognitoUser.authenticateUser(this.AuthenticationDetails,cognitoCallbacks)
                    this.code = ''
                    if(instance.VerificationSent == 'true')
                        instance._showToast('OTP sent successfully', { variant: 'success' })
                }
                else
                {
                    this.IsLockout = true
                    this._showToast(res.error.message, { variant: 'warning' })
                }
            })
        },
        GetDeviceStorage()
        {
            return JSON.parse(localStorage.getItem(instance.email  + '_' + `${conf.USER_POOL_ID}` + ':deviceStorage'))
        },
        SetDeviceStorage()
        {
            localStorage.setItem(instance.email  + '_' + `${conf.USER_POOL_ID}` + ':deviceStorage',JSON.stringify(userPool.storage))
        },
        async resendMFA() {
            await authService.GetUserDataId(this.email,this.password,'',instance.VerificationSent == 'true' ?'Y':'').then(res => {
                if (!res.error) {
                    userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
                    instance = this
                    cognitoUser = new AmazonCognitoIdentity.CognitoUser(this.UserData)
                    if(IsRememberDevice == 0)
                    {
                        let arr = ['243A6021-47A0-4912-A101-C4AB08C053C8','1E890D44-A048-4A69-A951-455D739FF8D4','35FF6F68-0A95-40FB-BB54-895C484E9F38','39C7744F-41FD-4EEE-94CA-322331EB046A','app-version']
                        for (var j = 0; j<localStorage.length; j++) {
                            for (var i = 0; i<localStorage.length; i++) {

                                if(!arr.includes(localStorage.key(i)))
                                {
                                    localStorage.removeItem(localStorage.key(i))

                                }
                            }
                        }
                    }
                    else
                    {
                        userPool.storage = this.GetDeviceStorage()
                    }
                    IsResendOTP = true
                    IsCodeSent = true
                    if(instance.VerificationSent != 'true')
                        cognitoUser.authenticateUser(this.AuthenticationDetails,cognitoCallbacks)
                    this.code = ''
                }
                else
                {
                    this.IsLockout = true
                    this._showToast(res.error.message, { variant: 'warning' })
                }
            })
        },
        toggleShow() {
            this.show = !this.show
        },
    },
    watch: {
        selectUserTypes: function (v) {
            if(IsVerified)
                this._showToast('Your phone number has been verified', { variant: 'success' })
            if (!v.login_as_required) return
            this.mustSelectUserType = true
            this.userData = v
            this.userData.p = this.password
            this.userData.e = this.email
        },
        email(val) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            var b = re.test(String(this.email.trim()).toLowerCase())
            this.isLoginEnabled = !b ? false : true
        },
        password(val) {
            this.isLoginEnabled = (this.password == null || this.password == undefined || this.password == '') ? false : true
        },
    }
}