import _ from 'lodash'
import { AUTH_ACTIONS } from '../../../../store/actions'
import { USER_TYPE } from '../../../../misc/commons'
export default {
    props: {
        hide: Function,
        dataType: Object
    },
    data() {
        return {
            usersList: null,
            searchQuery: '',
            isShowSelect: false,
            typeLogin: null,
            userSelected: {
                id: null,
                name: '',
                type: null
            },
            USER_TYPE: USER_TYPE
        }
    },
    methods: {
        selectType: function (type) {
            this.userSelected = {
                id: null,
                name: '',
                type: null
            }
            this.typeLogin = type
            this.searchQuery = ''
            switch (type) {
            case USER_TYPE.NOTI:
                this.usersList = null
                break
            case USER_TYPE.PROV:
                this.usersList = this.dataType.providers
                break
            case USER_TYPE.SUPP:
                this.usersList = this.dataType.suppliers
                break
            default:
                this.usersList = null
                break
            }
        },
        toggleSelect: function (e) {
            this.isShowSelect = !this.isShowSelect
            this.searchQuery = ''
            e.stopPropagation()
        },
        hideSelect: function () {
            this.isShowSelect = false
        },
        selectUser: function (item) {
            if (item && item.id) {
                Object.assign(this.userSelected, item)
            }
        },
        submitLogin: function () {
            if (this.typeLogin === USER_TYPE.NOTI) {
                this.$store.dispatch(AUTH_ACTIONS.LOGIN, {
                    client_id: 'web-client',
                    grant_type: 'password',
                    email: this.dataType.e,
                    password: this.dataType.p,
                    login_as: this.typeLogin,
                    login_as_org_id: 0
                })
            } else {
                this.$store.dispatch(AUTH_ACTIONS.LOGIN, {
                    client_id: 'web-client',
                    grant_type: 'password',
                    email: this.dataType.e,
                    password: this.dataType.p,
                    login_as: this.typeLogin,
                    login_as_org_id: this.userSelected.id
                })
            }

        },
        fetchSelectedUsername(name, type) {
            let _name = ''
            if (name) {
                _name = name
            } else {
                if (type === USER_TYPE.PROV) {
                    _name = 'Select Provider'
                } else if (type === USER_TYPE.SUPP) {
                    _name = 'Select Supplier'
                }
            }
            return _name
        }
    },
    computed: {
        disableBtn() {
            let validData = _.cloneDeep(this.userSelected)
            return validData.id || this.typeLogin === USER_TYPE.NOTI ? false : true
        },
        filteredUsers() {
            return this.usersList.filter(user => user.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
        }
    }
}